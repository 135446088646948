
.content {
  background-image: url("../assets/hero_image.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.form {
  background-color: white;
  border-radius: 10px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-left: 65%;
  margin-right: 3%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  max-width: 25rem;
}

.title {
  color: #00a278;
}

.secondary-action {
  padding: 1rem 0;
}

.link {
  font-weight: 700;
  text-decoration: none;
  color: #45c09f;
}

.link:focus {
  color: #00a278;
}

.link:active {
  color: #45c09f;
}

.link:visited {
  color: #45c09f;
}

.button {
  background-color: #00a278 !important;
}

.button:hover {
  background-color: #00845c !important;
}

@media screen and (max-width: 768px) {

  .consent {
    justify-content: flex-end;
  }
  .form {
    margin: 24rem;
  }
}
