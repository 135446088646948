.card {
    /* height: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: space-around ; */
}

.card-actions {
    margin-bottom: 1rem;
}

.card-button {
    text-transform: none;
    font-weight: 600;
}

.card:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

