#add-new-btn {
    text-transform: none;
    font-weight: 700;
    margin: 1rem 0;
}

.address-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 5px;
    cursor: pointer;
}

.shipping-container {
    padding: 2rem 1rem;
}

.selected {
    border: 1px solid #00A278;
    background-color: #E9F5E1;
}

.not-selected {
    border: 1px solid #ddd;
}

p {
    color: #3C3C3C;
}

@media screen and (min-width: 768px) {
    .shipping-container {
        padding: 2rem 4rem;
    }
}


/* ////////////////////////////Button  */


.button-82-pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 1px;
    transition: filter 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-82-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition:
      transform
      600ms
      cubic-bezier(.3, .7, .4, 1);
  }
  
  .button-82-edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(
      to left,
      hsl(228deg 100% 16%) 0%,
      hsl(228deg 100% 32%) 8%,
      hsl(228deg 100% 32%) 92%,
      hsl(228deg 100% 16%) 100%
    );
  }
  
  .button-82-front {
    display: block;
    position: relative;
    padding: 12px 27px;
    border-radius: 8px;
    font-size: 0.6rem;
    color: white;
    background: hsl(213deg 100% 47%);
    will-change: transform;
    transform: translateY(-4px);
    transition:
      transform
      600ms
      cubic-bezier(.3, .7, .4, 1);
  }
  
  @media (min-width: 768px) {
    .button-82-front {
      font-size: 1rem;
      padding: 12px 42px;
    }
  }
  
  .button-82-pushable:hover {
    filter: brightness(110%);
    -webkit-filter: brightness(110%);
  }
  
  .button-82-pushable:hover .button-82-front {
    transform: translateY(-6px);
    transition:
      transform
      250ms
      cubic-bezier(.3, .7, .4, 1.5);
  }
  
  .button-82-pushable:active .button-82-front {
    transform: translateY(-2px);
    transition: transform 34ms;
  }
  
  .button-82-pushable:hover .button-82-shadow {
    transform: translateY(4px);
    transition:
      transform
      250ms
      cubic-bezier(.3, .7, .4, 1.5);
  }
  
  .button-82-pushable:active .button-82-shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }
  
  .button-82-pushable:focus:not(:focus-visible) {
    outline: none;
  }